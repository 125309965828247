import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { properties } from "../../content/Properties"
import InnerPropertyPage from "../../components/molecules/InnerPropertyPage"

const slug = `8-drimpton-st-davoren-park-sa-5113`

const post = properties.find(post => post.slug === slug) // Find the blog post by slug

const DrimptonStDavorenParkSa5113 = () => (
  <Layout>
    <Seo
      title="NDIS Supported SDA Property for Disable People in Davoren Park"
      description="Discover accessible, supportive living in Davoren Park with NDIS-supported housing. Our vacancies offer specialised services for people with disabilities—learn more today!"
    />

    <InnerPropertyPage post={post} />

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default DrimptonStDavorenParkSa5113
